<template>
  <div class="container" ref="wrapper">
    <van-search
      @search="onSearch"
      v-model="page.filterData.detailsText"
      placeholder="请输入搜索关键词"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="comment-list">
        <van-list
          v-model="upLoading"
          :finished="finished"
          @load="onLoad"
          finished-text="没有更多了"
        >
          <div
            class="item van-hairline--bottom van-hairline--top"
            v-for="comment in articles"
            :key="comment.id.toString()"
            @click="toDetail(comment)"
          >
            <van-image
              round
              width="41px"
              height="41px"
              fit="fill"
              :src="comment.headImgurl"
            />
            <div class="info">
              <p>
                <span class="name">{{ comment.trueName }}</span>
                <userIcon :iconstring="comment.icon"></userIcon>
              </p>
              <p>{{ comment.detailsText }}</p>
              <div>
                <span class="time" v-if="comment.sourceType == 1">线下</span
                >&nbsp;
                <span class="time">{{ comment.creationTime | relTime }}</span
                >&nbsp;
                <van-tag plain>{{ comment.replyCount }} 回复</van-tag>
                <div style="float: right">
                  <van-tag color="#9d1d22">
                    {{ getTypeText(comment.itemType) }}</van-tag
                  >
                </div>
              </div>

              <!--回复详情-->
              <div
                class="item van-hairline--bottom van-hairline--top"
                style="background-color: #f9f8f8"
                v-for="replycomment in comment.replyList"
                :key="replycomment.id"
              >
                <van-image
                  round
                  width="41px"
                  height="41px"
                  fit="fill"
                  :src="replycomment.headImgurl"
                />
                <div class="info">
                  <p>
                    <span class="name">{{ replycomment.trueName }}</span>
                    <userIcon :iconstring="replycomment.icon"></userIcon>
                  </p>
                  <p>{{ replycomment.detailsText }}</p>
                  <div>
                    <span class="time">{{
                      replycomment.creationTime | relTime
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyAcceptCommentList } from "@/api/commentLike";

import UserIcon from "../components/userIcon";

//const key = "zn-history";
export default {
  name: "mycomment",
  data() {
    return {
      scroll: 0,
      refreshing: false,

      title: "我收到的评论",

      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          detailsText: "",
        },
      },
    };
  },
  components: {
    UserIcon,
  },
  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
    },
    toDetail(item) {
      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      if (item.itemType == 1) {
        this.$router.push({
          path: "/article/detail",
          query: { para: item.itemId,back:true },
        });
      } else if (item.itemType == 2) {
        this.$router.push({
          path: "/product/detail",
          query: { para: item.itemId,back:true  },
        });
      } else if (item.itemType == 3) {
        this.$router.push({
          path: "/course/detail",
          query: { para: item.itemId,back:true  },
        });
      } else if (item.itemType == 4) {
        this.$router.push({
          path: "/bbs/detail",
          query: { para: item.itemId,back:true  },
        });
      } else if (item.itemType == 5) {
        this.$router.push({
          path: "/clouddisk/detail",
          query: { para: item.itemId,back:true  },
        });
      } else if (item.itemType == 10) {
        this.$router.push({
          path: "/course/courseofflinedetail",
          query: { para: item.itemId,back:true  },
        });
      } else if (item.itemType == 12) {
        this.$router.push({
          path: "/worklog/detail",
          query: { id: item.itemId,back:true  },
        });
      }
    },

    getTypeText(id) {
      switch (id) {
        case 12: {
          return "工作日志";
        }
        case 4: {
          return "中酿心声";
        }
        default: {
          return "";
        }
      }
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getMyAcceptCommentList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.comment-list {
  padding: 0px 10px 20px;

  /deep/ .item {
    display: flex;
    padding: 10px 0;
    .info {
      flex: 1;
      padding-left: 10px;
      .name {
        color: #069;
      }
      .zan {
        vertical-align: middle;
        padding-right: 2px;
      }
      .count {
        vertical-align: middle;
        font-size: 10px;
        color: #666;
      }
      .time {
        color: #666;
      }
      p {
        padding: 5px 0;
        margin: 0;
      }
    }
  }
  /deep/ .van-button:active::before {
    background: transparent;
  }
  /deep/.van-icon-good-job {
    color: red;
  }

  /deep/ .van-icon-star {
    color: red;
  }
}
.reply-container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background: #f5f5f5;
  z-index: 2000;
  .submit {
    font-size: 12px;
    color: #3296fa;
  }
}
.reply_dialog {
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
  .van-action-sheet__content {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 44px;
  }
}

.mydivider {
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
  border-width: 0;
}
.mydivider::before {
  content: "";
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-color: inherit;
  border-style: inherit;
  border-width: 5px 0 0;
}
</style>
